import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { ULPFormData } from "./models/ULPForm";
import {
	Button,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	CircularProgress,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { panRegx, pincodeRegx } from "../../../utils/commonRegExs";
import { toUpper } from "lodash";
import {
	DatePicker,
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DayjsFnsUtils from "@date-io/dayjs";
import { RLPFormData } from "./models/RLPForm";
import { connect, ConnectedProps } from "react-redux";
import {
	actions,
	fetchPanDetails,
	fetchPincodeDetails,
	updateApplicant,
	panDetailsVerificationByNSDL
} from "../../../store/application/actions";
import useApplication from "../../../hooks/applicationHook";
import { RootState } from "../../../config/store";
import {
	checkForDoneAction,
	checkForErrors,
	checkForLoading,
} from "../../../utils/helpers";
import { ApplicantMapper } from "./ApplicantMapper";
import { ApplicantEntity } from "../../../services/application/models/ApplicantEntity";
import { removeDoneActions, removeErrors } from "../../../store/ui/actions";
import { Autocomplete } from "@material-ui/lab";
// import { checkStatus, checkResponseCode } from "../../../utils/apiUtils";
import { StateList } from "./models/StateList";
import { toast } from "react-toastify";
import { PanDetailsForm } from "./models/PanDetailsForm";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: 16,
		},
		margin: {
			marginBottom: theme.spacing(3),
		},
	})
);

const mapStateToProps = (state: RootState) => {
	return {
		panLoader: checkForLoading(
			state.ui.spinners,
			actions.FETCH_PAN_DETAILS
		),
		isPanDetailsFetched: checkForDoneAction(
			state.ui.spinners,
			actions.FETCH_PAN_DETAILS
		),
		isThereErrorOnPanFetch: checkForErrors(
			state.ui.errors,
			actions.FETCH_PAN_DETAILS
		),
		pincodeLoader: checkForLoading(
			state.ui.spinners,
			actions.FETCH_PINCODE_DETAILS
		),
		isPincodeDetailsFetched: checkForDoneAction(
			state.ui.spinners,
			actions.FETCH_PINCODE_DETAILS
		),
		updateApplicantLoader: checkForLoading(
			state.ui.spinners,
			actions.UPDATE_APPLICANT
		),
		panDetailsVerifiedByNSDL: checkForDoneAction(
			state.ui.doneActions,
			actions.PAN_DETAILS_VERIFICATION_BY_NSDL
		),
		panDetailsVerifying: checkForLoading(
			state.ui.spinners,
			actions.PAN_DETAILS_VERIFICATION_BY_NSDL
		),
		panDetailsVerificationResult: state.application.panDetailsVerificationResult,
		ui: state.ui,
	};
};

const mapDispatchToProps = {
	fetchPanDetails,
	fetchPincodeDetails,
	updateApplicant,
	removeErrors,
	removeDoneActions,
	panDetailsVerificationByNSDL
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

type ApplicantRlpFormProps = {
	defaults: RLPFormData;
};

const profession = [
	{
	  label: "Chit Fund, Money Lender",
	  value: "Chit Fund, Money Lender"
	},
	{
	  label: "Department Store",
	  value: "Department Store"
	},
	{
	  label: "Government Sector",
	  value: "Government Sector"
	},
	{
	  label: "Gambling, Casino",
	  value: "Gambling, Casino"
	},
	{
	  label: "IT & BPM",
	  value: "IT & BPM"
	},
	{
	  label: "Agriculture and Allied Industries",
	  value: "Agriculture and Allied Industries"
	},
	{
	  label: "Gems and Jewellery",
	  value: "Gems and Jewellery"
	},
	{
	  label: "Media and Entertainment",
	  value: "Media and Entertainment"
	},
	{
	  label: "Automobiles",
	  value: "Automobiles"
	},
	{
	  label: "Petrol Pump",
	  value: "Petrol Pump"
	},
	{
	  label: "Telecommunications",
	  value: "Telecommunications"
	},
	{
	  label: "Aviation",
	  value: "Aviation"
	},
	{
	  label: "Real Estate",
	  value: "Real Estate"
	},
	{
	  label: "Tourism and Hospitality",
	  value: "Tourism and Hospitality"
	},
	{
	  label: "Banking/Financial Services",
	  value: "Banking/Financial Services"
	},
	{
	  label: "Security Guard Services",
	  value: "Security Guard Services"
	},
	{
	  label: "Collections and Recoveries",
	  value: "Collections and Recoveries"
	},
	{
	  label: "Cement, Chemicals",
	  value: "Cement, Chemicals"
	},
	{
	  label: "Police",
	  value: "Police"
	},
	{
	  label: "FMCG",
	  value: "FMCG"
	},
	{
	  label: "Politician",
	  value: "Politician"
	},
	{
	  label: "E-Commerce",
	  value: "E-Commerce"
	},
	{
	  label: "Lawyer",
	  value: "Lawyer"
	},
	{
	  label: "Education and Training",
	  value: "Education and Training"
	},
	{
	  label: "Defence Manufacturing",
	  value: "Defence Manufacturing"
	},
	{
	  label: "Engineering and Capital Goods",
	  value: "Engineering and Capital Goods"
	},
	{
	  label: "Healthcare/Pharmaceuticals",
	  value: "Healthcare/Pharmaceuticals"
	},
	{
	  label: "Infrastructure",
	  value: "Infrastructure"
	},
	{
	  label: "Manufacturing",
	  value: "Manufacturing"
	},
	{
	  label: "Oil and Gas/Power",
	  value: "Oil and Gas/Power"
	},
	{
	  label: "Railways/Roads/Ports",
	  value: "Railways/Roads/Ports"
	}
];

const ApplicantRlpForm: React.FC<ApplicantRlpFormProps & ReduxProps> = (
	props
) => {
	const { application, panDetails, pincodeDetails, panDetailsVerificationResult } = useApplication();
	const [panError, setPanError] = useState(undefined);
	const [manualEntryRequired, setManualEntryRequired] = useState(false);
	const [manualPanDetailsVerified, setManualPanDetailsVerified] = useState(false);

	const {
		handleSubmit,
		watch,
		setValue,
		errors,
		control,
		setError,
	} = useForm<RLPFormData>({
		mode: "onChange",
		defaultValues: props.defaults,
	});

	const onSubmit = handleSubmit((data: RLPFormData) => {
		const mappedReq = ApplicantMapper.MapRlp(
			data,
			application?.CreationReference!
		);

		props.updateApplicant({ ...mappedReq, PanDetailsManuallyEntered: manualEntryRequired });
	});

	const onVerifyPanDetails = handleSubmit((data:PanDetailsForm) => {
		props.panDetailsVerificationByNSDL({
			InstaId: application?.CreationReference as string,
			IsCoBorrower: false,
			Name: data.applicantName,
			PanNumber: data.pan,
			DateOfBirth: data.dateOfBirth
		})
	})

	const classes = useStyles();
	const showPanLoader = props.panLoader;
	const showPincodeLoader = props.pincodeLoader;

	const isNotEarning = watch("isEarning") === "no";
	const isSalaried = watch("employmentType") === "salaried";

	useEffect(() => {
		if (panDetails && props.isPanDetailsFetched) {
			if (panDetails.ManualEntryRequired) {
				setManualEntryRequired(true);
			}
			else {
				setManualEntryRequired(false);
				setValue("applicantName", panDetails?.PanDetails.Name);
				setValue("fathersName", panDetails?.PanDetails.FatherName);
			}


			props.removeDoneActions([actions.FETCH_PAN_DETAILS]);
		}
	}, [props.isPanDetailsFetched, panDetails]);

	useEffect(() => {
		if (props.isThereErrorOnPanFetch) {
			const err = props.ui.errors[actions.FETCH_PAN_DETAILS][0];
			const errorMessage = err.description || "Invalid PAN number!";
			if (err.errType == "field") {
				setValue("applicantName", "");
				setValue("fathersName", "");
				setError("pan", {
					message: errorMessage,
					types: {
						required: err.description,
					},
					type: "required",
				});
				setPanError(errorMessage);
			}
			props.removeErrors([actions.FETCH_PAN_DETAILS]);
		}
	}, [props.isThereErrorOnPanFetch]);

	useEffect(() => {
		if (props.panDetailsVerifiedByNSDL) {
			if (panDetailsVerificationResult?.PanDetailsVerified) {
				setManualPanDetailsVerified(true);
				toast.success("Verified Successfully!");
			}
			else {
				if (!panDetailsVerificationResult?.NameMatch) {
					toast.error("Provide a valid Name as per PAN Card");
				}
				if (!panDetailsVerificationResult?.DOBMatch) {
					toast.error("Provide a valid Date of Birth as per PAN Card");
				}
			}
			props.removeDoneActions([actions.PAN_DETAILS_VERIFICATION_BY_NSDL])
		}

	}, [props.panDetailsVerifiedByNSDL])

	useEffect(() => {
		if (pincodeDetails && props.isPincodeDetailsFetched) {
			setValue("currentAddressCity", pincodeDetails?.Details.City);
			setValue("currentAddressState", pincodeDetails?.Details.State.toUpperCase());

			props.removeDoneActions([actions.FETCH_PINCODE_DETAILS]);
		}
	}, [props.isPincodeDetailsFetched, pincodeDetails]);
	// useEffect(()=>{
	// 	fetchSearchState("state")
	// },[])
	const onPanUpdate = (value: string) => {
		if (/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(value) !== true) {
			return;
		}
		if (value.length === 10) {
			setManualEntryRequired(false);
			setManualPanDetailsVerified(false);
			setValue("applicantName", "");
			setValue("dateOfBirth", null);
			props.fetchPanDetails({
				IsCoBorrower: false,
				ApplicationCreationReference: application?.CreationReference!,
				PanNumber: value,
			});
		}
	};

	const onPincodeUpdate = (value: string) => {
		if (!value) return;
		if (value.length !== 6) return;

		props.fetchPincodeDetails({
			ApplicationCreationReference: application?.CreationReference!,
			IsCoBorrower: false,
			Pincode: value,
		});
	};

	// const fetchSearchState = (type:string) => {
	// 	let baseURL = config.staticData + "";
	// 	const companySearchEndpoint = "static/company/search?q=";
	// 	const professionSearchEndpoint = "static/profession/search?q=";
	// 	const stateSearchEndpoint = "static/state/get/all";
	// 	const citySearchEndpoint = "static/city/search?q=";
	// 	const instituteBrandSearch = "university/institute-brand/search/brand-top?q=";

	// 	switch (type) {
	// 	case "university":
	// 		baseURL += instituteBrandSearch;
	// 		break;
	// 	case "company":
	// 		baseURL += companySearchEndpoint;
	// 		break;
	// 	case "profession":
	// 		baseURL += professionSearchEndpoint;
	// 		break;
	// 	case "state":
	// 		baseURL += stateSearchEndpoint;
	// 		break;
	// 	case "city":
	// 		baseURL += citySearchEndpoint;
	// 		break;
	// 	}

	// 	fetch(baseURL)
	// 	.then(checkStatus)
	// 	.then(checkResponseCode)
	// 	.then((response) => {
	// 		setSearchedState(response.PayLoad)
	// 	})
	// 	.catch(err => console.log('err',err));
	// }

	return (
		<form className={classes.root} onSubmit={onSubmit}>
			<Controller
				name="isEarning"
				rules={{
					required: {
						value: true,
						message: "Field is required!",
					},
				}}
				render={(props) => {
					return (
						<FormControl
							error={!!errors.isEarning}
							fullWidth
							disabled
							variant="outlined"
							className={classes.margin}
							data-test-id="applicant-details__is-earning-selection"
						>
							<InputLabel>Is Applicant Earning?</InputLabel>
							<Select
								value={props.value}
								onChange={(e) => {
									props.onChange(e);
								}}
								label="Is Applicant Earning?"
							>
								<MenuItem value={"yes"}>Yes</MenuItem>
								<MenuItem value={"no"}>No</MenuItem>
							</Select>
							{!!errors.isEarning && (
								<FormHelperText data-test-id="applicant-details__is-earning-selection-error">
									{errors.isEarning.message}
								</FormHelperText>
							)}
						</FormControl>
					);
				}}
				control={control}
			/>
			<Controller
				name="pan"
				render={(props) => {
					return (
						<TextField
							className={classes.margin}
							variant="outlined"
							label="PAN"
							data-test-id="applicant-details__pan"
							value={props.value}
							fullWidth
							error={!!errors.pan}
							helperText={errors.pan?.message || (
								manualEntryRequired && (
									<>
									{manualPanDetailsVerified ?
										"* Entered PAN Details are Verified through NSDL." :
										"* Failed to get PAN details from NSDL. Please do enter manually"
									}
									</>
								)
							)}
							onChange={(e) => {
								setPanError(undefined);
								onPanUpdate(toUpper(e.target.value));
								props.onChange(toUpper(e.target.value));
							}}
							InputProps={{
								endAdornment: showPanLoader ? (
									<CircularProgress size="1.5rem" />
								) : (
									""
								),
							}}
						/>
					);
				}}
				rules={{
					required: { value: true, message: "Required Field!" },
					pattern: { value: panRegx, message: "Invalid PAN number!" },
					validate: (val) => {
						if (!panDetails) {
							return panError;
						}
						return true;
					},
				}}
				control={control}
			/>
			
			<Controller
				as={TextField}
				fullWidth
				disabled={manualEntryRequired && manualPanDetailsVerified}
				name="applicantName"
				data-test-id="applicant-details__name"
				className={classes.margin}
				variant="outlined"
				label="Full Name"
				error={!!errors.applicantName}
				helperText={errors.applicantName?.message}
				rules={{
					required: { value: true, message: "Name is required!" },
				}}
				control={control}
			/>

			<Controller
				name="dateOfBirth"
				control={control}
				defaultValue={null}
				rules={{
					required: {
						value: true,
						message: "Date Of Birth Required!",
					},
				}}
				render={(props) => {
					return (
						<MuiPickersUtilsProvider utils={DayjsFnsUtils}>
							<KeyboardDatePicker
								disableFuture
								fullWidth
								error={!!errors.dateOfBirth}
								data-test-id="applicant-details__dob-date-picker"
								helperText={errors.dateOfBirth?.message}
								openTo="year"
								className={classes.margin}
								inputVariant="outlined"
								format="DD/MM/YYYY"
								disabled={manualEntryRequired && manualPanDetailsVerified}
								views={["year", "month", "date"]}
								placeholder="DD/MM/YYYY"
								label="Date Of Birth"
								value={props.value}
								onChange={(date) => {
									props.onChange(date?.format("YYYY-MM-DD"));
								}}
							/>
						</MuiPickersUtilsProvider>
					);
				}}
			/>
			{console.log(props.panDetailsVerifying,"props.panDetailsVerifying")}
			{manualEntryRequired && !manualPanDetailsVerified && (
				<Button
					startIcon={
						props.panDetailsVerifying ? (
							<CircularProgress color="inherit" size="1rem" />
						) : undefined
					}
					variant="contained"
					color="primary"
					data-test-id="applicant-details__verify-pan-btn"
					onClick={onVerifyPanDetails}
				>
					Verify PAN Details
				</Button>
			)}

			{(!manualEntryRequired || manualPanDetailsVerified) && (
				<>
					<Controller
						as={TextField}
						fullWidth
						name="fathersName"
						data-test-id="applicant-details__father-name"
						className={classes.margin}
						variant="outlined"
						label="Father's Name"
						error={!!errors.fathersName}
						helperText={errors.fathersName?.message}
						rules={{
							required: { value: true, message: "Father's is required!" },
						}}
						control={control}
					/>
					<Controller
						name="gender"
						rules={{
							required: {
								value: true,
								message: "Gender is required!",
							},
						}}
						render={(props) => {
							return (
								<FormControl
									error={!!errors.gender}
									fullWidth
									variant="outlined"
									data-test-id="applicant-details__gender"
									className={classes.margin}
								>
									<InputLabel>Gender</InputLabel>
									<Select
										value={props.value}
										onChange={(e) => {
											props.onChange(e);
										}}
										label="Gender"
									>
										<MenuItem value={"male"}>Male</MenuItem>
										<MenuItem value={"female"}>Female</MenuItem>
										<MenuItem value={"other"}>Other</MenuItem>
									</Select>
									{!!errors.gender && (
										<FormHelperText data-test-id="applicant-details__gender-selection-error">
											{errors.gender.message}
										</FormHelperText>
									)}
								</FormControl>
							);
						}}
						control={control}
					/>

					{!isNotEarning && (
						<Controller
							name="employmentType"
							rules={{
								required: {
									value: true,
									message: "Field is required!",
								},
							}}
							render={(props) => {
								return (
									<FormControl
										error={!!errors.employmentType}
										fullWidth
										variant="outlined"
										className={classes.margin}
										data-test-id="applicant-details__employment-type"
									>
										<InputLabel>Salaried/Self-Employed?</InputLabel>
										<Select
											value={props.value}
											onChange={(e) => {
												props.onChange(e);
											}}
											label="Salaried/Self-Employed?"
										>
											<MenuItem value={"salaried"}>
												Salaried
											</MenuItem>
											<MenuItem value={"self-employed"}>
												Self-Employed
											</MenuItem>
										</Select>
										{!!errors.employmentType && (
											<FormHelperText data-test-id="applicant-details__employment-type-selection-error">
												{errors.employmentType.message}
											</FormHelperText>
										)}
									</FormControl>
								);
							}}
							control={control}
						/>
					)}

					{!isNotEarning && (
						<Controller
							name="profession"
							rules={{
								required: {
									value: true,
									message: "Profession is required!",
								},
							}}
							control={control}
							render={(props) => {
								return (
									<FormControl
										error={!!errors.profession}
										fullWidth
										variant="outlined"
										className={classes.margin}
										data-test-id="applicant-details__profession"
									>
										<InputLabel>Profession</InputLabel>
										<Select
											value={props.value}
											onChange={(e) => {
												props.onChange(e);
											}}
											label="Profession"
										>
											{
												profession.map(element => (
													<MenuItem value={element.value}>
														{element.label}
													</MenuItem>
												))
											}
										</Select>
										{!!errors.profession && (
											<FormHelperText>
												{errors.profession.message}
											</FormHelperText>
										)}
									</FormControl>
								)
							}}
						/>
					)}

					{isSalaried && (
						<Controller
							as={TextField}
							fullWidth
							name="companyName"
							data-test-id="applicant-details__company-name"
							className={classes.margin}
							variant="outlined"
							label="Company"
							error={!!errors.companyName}
							helperText={errors.companyName?.message}
							rules={{
								required: {
									value: true,
									message: "Company is required!",
								},
							}}
							control={control}
						/>
					)}

					{!isNotEarning && (
						<Controller
							name="earningForYears"
							rules={{
								required: {
									value: true,
									message: "Field is required!",
								},
							}}
							render={(props) => {
								return (
									<FormControl
										error={!!errors.earningForYears}
										fullWidth
										variant="outlined"
										className={classes.margin}
										data-test-id="applicant-details__earning-for-years"
									>
										<InputLabel>
											How long Applicant is Earning?
										</InputLabel>
										<Select
											value={props.value}
											onChange={(e) => {
												props.onChange(e);
											}}
											label="How long Applicant is Earning?"
										>
											<MenuItem value={0.4}>{"< 6m"} </MenuItem>
											<MenuItem value={0.8}>6 - 12m</MenuItem>
											<MenuItem value={2}>1 - 3yrs</MenuItem>
											<MenuItem value={3.5}>{"> 3yrs"}</MenuItem>
										</Select>
										{!!errors.earningForYears && (
											<FormHelperText data-test-id="applicant-details__earning-for-years-selection-error">
												{errors.earningForYears.message}
											</FormHelperText>
										)}
									</FormControl>
								);
							}}
							control={control}
						/>
					)}

					<Controller
						name="currentAddressPincode"
						render={(props) => {
							return (
								<TextField
									className={classes.margin}
									variant="outlined"
									label="Current Address Pincode"
									data-test-id="applicant-details__current-address-pincode"
									value={props.value}
									fullWidth
									inputProps={{
										maxLength: 6,
									}}
									error={!!errors.currentAddressPincode}
									helperText={errors.currentAddressPincode?.message}
									onChange={(e) => {
										onPincodeUpdate(toUpper(e.target.value));
										props.onChange(toUpper(e.target.value));
									}}
									InputProps={{
										endAdornment: showPincodeLoader ? (
											<CircularProgress size="1.5rem" />
										) : (
											""
										),
									}}
								/>
							);
						}}
						rules={{
							required: { value: true, message: "Required Field!" },
							pattern: {
								value: pincodeRegx,
								message: "Invalid Pincode!",
							},
						}}
						control={control}
					/>

					<Controller
						as={TextField}
						fullWidth
						name="currentAddressLine1"
						data-test-id="applicant-details__current-address-line-1"
						className={classes.margin}
						variant="outlined"
						label="Current Address (House/Flat No)"
						error={!!errors.currentAddressLine1}
						helperText={errors.currentAddressLine1?.message}
						rules={{
							required: {
								value: true,
								message: "House/Flat No is required!",
							},
						}}
						control={control}
					/>

					<Controller
						as={TextField}
						fullWidth
						name="currentAddressLine2"
						data-test-id="applicant-details__current-address-line-2"
						className={classes.margin}
						variant="outlined"
						label="Current Address Line 1"
						error={!!errors.currentAddressLine2}
						helperText={errors.currentAddressLine2?.message}
						rules={{
							required: {
								value: true,
								message: "Address Line 1 is required!",
							},
						}}
						control={control}
					/>

					<Controller
						as={TextField}
						fullWidth
						name="currentAddressCity"
						data-test-id="applicant-details__current-address-city"
						className={classes.margin}
						variant="outlined"
						label="Current Address City"
						error={!!errors.currentAddressCity}
						helperText={errors.currentAddressCity?.message}
						rules={{
							required: {
								value: true,
								message: "City is required!",
							},
						}}
						control={control}
					/>

					{/* <Controller
				as={TextField}
				fullWidth
				name="currentAddressState"
				data-test-id="applicant-details__current-address-state"
				className={classes.margin}
				variant="outlined"
				label="Current Address State"
				error={!!errors.currentAddressState}
				helperText={errors.currentAddressState?.message}
				rules={{
					required: {
						value: true,
						message: "State is required!",
					},
				}}
				control={control}
			/> */}
					<Controller
						fullWidth
						name="currentAddressState"
						control={control}
						error={!!errors.currentAddressState}
						helperText={errors.currentAddressState?.message}
						rules={{
							required: {
								value: true,
								message: "State is required!",
							},
						}}
						render={(props) => {
							return (
								<Autocomplete
									options={StateList}
									value={props.value}
									onChange={(e, newValue) => {
										props.onChange(newValue);
									}}
									getOptionLabel={(option) => {
										if (option.label) {
											return (option.label)
										}
										else {
											return (option)
										}
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											className={classes.margin}
											data-test-id="applicant-details__current-address-state"
											label="Current Address State"
											variant="outlined"
										/>
									)}
								/>
							)
						}}
					/>

					<Button
						startIcon={
							props.updateApplicantLoader ? (
								<CircularProgress size="1rem" />
							) : undefined
						}
						disabled={props.updateApplicantLoader}
						variant="contained"
						color="primary"
						data-test-id="applicant-details__save-btn"
						onClick={onSubmit}
					>
						Save Details
					</Button>

				</>
			)}
		</form>
	);
};

export default connector(ApplicantRlpForm);
